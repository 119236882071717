import React from 'react';
import Footer from './Footer';
import Header from './Header';
import { Helmet } from 'react-helmet';

const Layout = ({ children, isRoot }) => {
  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>PDF Table Util</title>
          <link rel="canonical" href="http://mysite.com/example" />
          <meta name="title" content="PDF Table Util"/>
          <meta name="description" content="Extract tables from PDF documents."/>
          
          <meta property="og:type" content="website/"/>
          <meta property="og:url" content="https://pdftableutil.possiblenull.com/"/>
          <meta property="og:title" content="PDF Table Util"/>
          <meta property="og:description" content="Extract tables from PDF documents."/>
          <meta property="og:image" content="https://pdftableutil.possiblenull.com/images/meta.png"/>
          
          <meta property="twitter:card" content="summary_large_image"/>
          <meta property="twitter:url" content="https://pdftableutil.possiblenull.com/"/>
          <meta property="twitter:title" content="PDF Table Util"/>
          <meta property="twitter:description" content="Extract tables from PDF documents."/>
          <meta property="twitter:image" content="https://pdftableutil.possiblenull.com/images/meta.png"/>

          <link rel="apple-touch-icon" sizes="180x180" href="ico/apple-touch-icon.png"/>
          <link rel="icon" type="image/png" sizes="32x32" href="ico/favicon-32x32.png"/>
          <link rel="icon" type="image/png" sizes="16x16" href="ico/favicon-16x16.png"/>
          <link rel="manifest" href="ico/site.webmanifest"/>
      </Helmet>
      <Header isRoot={isRoot} />
      <main className="text-gray-900">{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
