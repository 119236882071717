import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../svg/LogoIcon';
import Button from '../Button';

const Header = ({isRoot}) => (
  <header className="sticky top-0 bg-white shadow">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <a href="/" className="flex items-center text-2xl" style={{color: '#222'}}>
        <div className="w-8 mr-3">
          <LogoIcon />
        </div>
        PDF Table Util
      </a>
      <div className="flex mt-4 sm:mt-0">
        {isRoot
          ?
            <>
              <AnchorLink className="px-4" href="#features">
                Features
              </AnchorLink>
              <AnchorLink className="px-4" href="#demo">
                Demo
              </AnchorLink>
            </>
          :
            <>
              <a className="px-4" href="/#features">
                Features
              </a>
              <a className="px-4" href="/#demo">
                Demo
              </a>
            </>
        }
        <a className="px-4" href="/privacy">
          Privacy Policy
        </a>
      </div>
      <div className="hidden md:block">
        <Button className="text-sm" onClick={() => {
          window.location = '/app';
        }}>Try Now!</Button>
      </div>
    </div>
  </header>
);

export default Header;
