import React from 'react';

const LogoIcon = () => (
<svg
   aria-hidden="true"
   focusable="false"
   data-prefix="fas"
   data-icon="file-pdf"
   role="img"
   viewBox="0 0 16.882248 16.882316"
   version="1.1"
   id="svg146"
   width="23.999992"
   height="41.328888"
   >
  <path
     id="path987"
     style={{fill:'#3182CE',fillOpacity:1,strokeWidth:0.0558254}}
     d="m 10.446825,-4.8608272 c -2.6309105,0.002 -5.2620165,-0.0025 -7.8928785,0.0036 -0.403985,0.03388 -0.75287,0.390612 -0.769058,0.797634 -0.583149,4.35622601 -1.16450501,8.712758 -1.74301201,13.069557 -0.02514,0.224672 -0.08612,0.461766 0.01207,0.678426 0.120328,0.3178562 0.439166,0.5528492 0.783024,0.5486512 0.49723001,0.003 0.99475201,6.19e-4 1.49213701,0.0016 1.921825,0 3.843648,0 5.765475,0 -0.910658,3.843876 -1.824223,7.687142 -2.730534,11.531998 -0.07432,0.430857 0.243373,0.882145 0.674245,0.957573 0.358145,0.07058 0.756591,-0.10966 0.91763,-0.442567 0.60398,-1.030333 1.19758,-2.066809 1.797665,-3.099458 2.6809525,-4.631292 5.3631935,-9.2618732 8.0420855,-13.8943372 0.213592,-0.405836 0.01464,-0.962225 -0.406741,-1.142604 -0.25735,-0.121599 -0.547048,-0.06473 -0.820827,-0.07736 -2.217549,0 -4.435096,0 -6.6526445,0 0.862889,-2.630124 1.7277765,-5.259705 2.5878355,-7.890681 0.117135,-0.462019 -0.233281,-0.984557 -0.712634,-1.03098 -0.113904,-0.01474 -0.229249,-0.01194 -0.343847,-0.011 z" />
  <path
     id="path987-5"
     style={{fill:'#BEE3F8',fillOpacity:1,strokeWidth:0.0603156}}
     d="m 8.7698115,-5.8597472 c -2.042511,0.0011 -4.085032,-4.95e-4 -6.127523,0.01007 -0.606458,4.551912 -1.21592,9.103428 -1.81331501,13.656535 3.01074201,0.005 6.02149001,0.0024 9.03223301,0.0028 C 8.9679595,11.580807 8.0716815,15.349271 7.1855165,19.121768 10.144121,14.012999 13.103719,8.9048108 16.05328,3.7908288 c -2.93414,2.47e-4 -5.868294,-9.8e-5 -8.8024405,0 1.055683,-3.21688899 2.115798,-6.432216 3.1600685,-9.651907 -0.5470315,3.71e-4 -1.0940645,9.9e-4 -1.6410965,0.0013 z" />
</svg>

);

export default LogoIcon;
